<template>
  <v-app>
    <v-img
      height="100%"
      width="100%"
      transition="fade-transition"
      src="http://lc-thxE3Imt.cn-n1.lcfile.com/e74e07d11c704cbd2f58/logbg.jpg"
    >
      <div v-if="alert" class="ma-6">
        <v-snackbar v-model="alert" top :color="type">{{ msg }}</v-snackbar>
      </div>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card tile align="center">
              <v-card-title class="justify-center"
                >欢迎来到Session Pro</v-card-title
              >
              <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    label="请输入邮箱"
                    prepend-inner-icon="mdi-email"
                    required
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    type="password"
                    :counter="16"
                    :rules="passwordRules"
                    prepend-inner-icon="mdi-lock"
                    label="请输入密码"
                    required
                  ></v-text-field>
                  <v-btn
                    x-large
                    block
                    :disabled="!valid"
                    :loading="loading"
                    color="primary"
                    class="mr-4"
                    @click="login"
                    >登录</v-btn
                  >
                  <v-container>
                    <v-row justify="center">
                      <v-col>
                        <v-btn
                          text
                          color="primary"
                          href="http://www.munshare.com/register"
                          target="_blank"
                          >没有模时账号? 马上注册</v-btn
                        >
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-app>
</template>
<script>
export default {
  metaInfo: {
    title: "登录",
  },
  data() {
    return {
      alert: false,
      type: "success",
      msg: "",
      loading: false,
      valid: true,
      rememberMe: false,
      email: "",
      emailRules: [
        (v) => !!v || "邮箱为必填项",
        (v) => /.+@.+\..+/.test(v) || "请输入合法的邮箱",
      ],
      password: "",
      passwordRules: [
        (v) => !!v || "密码是必填项",
        (v) => (v && v.length <= 16) || "密码长度必须不大于16位",
      ],
    };
  },
  computed: {},
  methods: {
    login: function () {
      if (this.$refs.form.validate()) {
        let data = {
          email: this.email,
          password: this.password,
        };
        this.loading = true;
        this.$store
          .dispatch("userLogin", data)
          .then(() => {
            this.loading = false;
            if (this.$store.getters.isPro) {
              this.alert = true;
              this.msg = "您已经解锁Pro，感谢你的支持。跳转中...";
            } else {
              this.alert = true;
              this.type = "warning";
              this.msg = "请您联系模时解锁Pro";
            }
            setTimeout(() => {
              this.$router.push("/account");
            }, 1500);
          })
          .catch((err) => {
            this.loading = false;
            if (err.code === 211) {
              this.msg = "您尚未注册，请注册后登录";
              this.alert = true;
              this.type = "warning";
            } else if (err.code === 210) {
              this.msg = "用户名和密码不匹配,请重试";
              this.alert = true;
              this.type = "warning";
            } else {
              this.msg = err + "，请联系我们";
              this.alert = true;
              this.type = "error";
              console.log(err);
            }
          });
      }
    },
  },
};
</script>
